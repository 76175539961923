/** Created by Tomáš Korec on 2019/10/22 */

/**
 * MODULES MANAGEMENT
 */
export const module = {
  VOIP: "voip",
  MOBILE: "mobile",
  SIP: "sip",
  UC: "uc",
  VIDEO: "video",
  BRANDING: "branding",
  DIAGNOSTICS: "diagnostics",
  PBX_ORDER: "pbx_order",
  INTERNET: "internet",
  SMS: "sms",
  SUPPORT: "support",
  HELPDESK: "helpdesk",
  VOICEBOT: "voicebot",
  CHATBOT: "chatbot",
  INVOICE_ITEM: "invoiceItem",
}

export const parseModules = allowedModules => ({
  [module.VOIP]: allowedModules.includes("VOIP"),
  [module.VIDEO]: allowedModules.includes("VIDEO"),
  [module.SIP]: allowedModules.includes("SIP"),
  [module.UC]: allowedModules.includes("UC"),
  [module.MOBILE]: allowedModules.includes("MOBILE"),
  [module.BRANDING]: allowedModules.includes("BRANDING"),
  [module.DIAGNOSTICS]: allowedModules.includes("DIAGNOSTICS"),
  [module.PBX_ORDER]: allowedModules.includes("PBX_ORDER"),
  [module.INTERNET]: allowedModules.includes("INTERNET"),
  [module.SMS]: allowedModules.includes("SMS"),
  [module.SUPPORT]: true,
  [module.HELPDESK]: allowedModules.includes("PBX_ORDER"),
  [module.HELPDESK_FB_MESSENGER]: true,
  [module.VOICEBOT]: true,
  [module.CHATBOT]: true,
  [module.INVOICE_ITEM]: true,
})

/**
 * MODULES UTILS
 */
const servicesMap = {
  [module.VOIP]: ["voice", "fax"],
  [module.MOBILE]: ["mobile"],
  [module.SIP]: ["connectors"],
  [module.UC]: ["integration", "pbx", "room"],
  [module.VIDEO]: ["zoomVideo", "room"],
  [module.INTERNET]: ["internet"],
  [module.SMS]: ["sms"],
  [module.SUPPORT]: ["support"],
  [module.HELPDESK]: ["helpdesk", "helpdeskFbMessenger", "helpdeskWebchat"],
  [module.VOICEBOT]: ["voicebot"],
  [module.CHATBOT]: ["chatbot"],
  [module.INVOICE_ITEM]: ["invoiceItem"],
}

export function getAllowedUserServices(providerModules) {
  return Object.keys(servicesMap).reduce(
    (userServices, moduleKey) =>
      providerModules[moduleKey] ? [...userServices, ...servicesMap[moduleKey]] : [...userServices],
    [],
  )
}
