/** Created by Tomáš Korec on 2020-1-12 */

import React from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"
import Head from "next/head"

import { useB2BExported } from "../../utils/hooks"

const useStyles = createUseStyles(theme => ({
  main: {
    paddingTop: ({ showLayout }) => (showLayout ? "3.5rem" : 0),
    height: "100vh",
    overflow: "auto",
    transition: "width 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    backgroundColor: ({ isDashboard }) => (isDashboard ? theme.color.grey100 : theme.color.white),
  },
  page: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  pageTitle: {
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    minHeight: "4rem",
    padding: "0.5rem 2rem",
    backgroundColor: theme.color.grey100,
  },
  breadcrumbs: {
    fontSize: "1.25rem",
    fontFamily: theme.fontFamily,
    flexDirection: "row",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    "& > a": {
      color: theme.color.grey400,
      textDecoration: "none",
      textAlign: "center",
      "&:hover": {
        color: theme.color.grey600,
      },
    },
    "& > em": {
      margin: "0 0.8rem",
      fontSize: "1rem",
      color: theme.color.grey400,
    },
  },
  title: {
    fontSize: "1.25rem",
  },
  toolbar: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-end",
    "& > *:not(:first-child)": {
      marginLeft: "0.5rem",
    },
    "& button": {
      display: "flex",
      flex: "0 1 auto",
      fontSize: "0.75rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      height: "2rem",
      margin: "0.2rem 0",
      "& *": {
        lineHeight: 1,
      },
    },
    "& > div": {
      display: "block",
      flex: "0 1 auto",
      fontSize: "0.75rem",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      height: "2rem",
      "& *": {
        lineHeight: 1,
      },
    },
  },
  pageContent: {
    flex: 1,
    overflow: "auto",
    padding: ({ isDashboard }) => (isDashboard ? "0rem 2rem 3rem 2rem" : "1rem 2rem 3rem 2rem"),
  },
}))

const Page = ({ title, breadcrumbs, toolbar, children, isDashboard }) => {
  const isExported = useB2BExported()
  const classes = useStyles({ isDashboard, showLayout: !useB2BExported() })

  return (
    <main className={classes.main}>
      <div className={classes.page}>
        <Head>
          <title>B2B IPEX - {title}</title>
        </Head>
        {!isExported && (title || breadcrumbs || toolbar) && (
          <div className={classes.pageTitle}>
            {title && !breadcrumbs && <div className={classes.title}>{title}</div>}
            <div className={classes.breadcrumbs}>{breadcrumbs}</div>
            <div className={classes.toolbar}>{toolbar}</div>
          </div>
        )}
        <div className={classes.pageContent}>{children}</div>
      </div>
    </main>
  )
}

Page.defaultProps = {
  isDashboard: false,
}

Page.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.node,
  toolbar: PropTypes.node,
  children: PropTypes.node,
  isDashboard: PropTypes.bool,
}

export default Page
