export const Action = {
  PUT: "put",
  ANIMATE_CLOSE: "animateClose",
  CLEAR: "clear",
  REMOVE: "remove",
}

export const alertReducer = (alerts, action) => {
  switch (action.type) {
    case Action.PUT:
      if (alerts.findIndex(alert => alert.id === action.alert.id) !== -1) {
        return alerts
      }
      return [...alerts, { ...action.alert }]
    case Action.CLEAR:
      return []
    case Action.REMOVE:
      const index = alerts.findIndex(alertItem => alertItem.id === action.id)
      const newAlerts = [...alerts]
      newAlerts.splice(index, 1)
      return newAlerts
    default:
      return alerts
  }
}
