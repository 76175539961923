import React, { useState } from "react"
import PropTypes from "prop-types"
import dynamic from "next/dynamic"

const FieldWrapper = dynamic(() => import("@ipex/storybook").then(mod => mod.Field))
const Autosuggest = dynamic(() => import("@ipex/storybook").then(mod => mod.Autosuggest))

const AutosuggestWrapper = ({
  data,
  errors,
  label,
  name,
  onChange,
  onSelect,
  tooltip,
  touched,
  ...props
}) => {
  const [suggestions, setSuggestions] = useState(data)

  const getSuggestions = (fulltext = "") => {
    setSuggestions(
      fulltext ? data.filter(i => i[name].toLowerCase().includes(fulltext.toLowerCase())) : data,
    )
  }

  const getSuggestionValue = suggestion => {
    onSelect(suggestion)
    return suggestion[name]
  }

  const renderSuggestion = suggestion => <div>{suggestion[name]}</div>

  return (
    <FieldWrapper
      label={label}
      error={touched[name] && errors[name]}
      component={
        <Autosuggest
          getSuggestionValue={getSuggestionValue}
          inputProps={{
            ...props,
            id: name,
            onChange: (e, newValue) => onChange(name, newValue.newValue),
            onKeyDown: e => {
              if (e.keyCode === 13) e.preventDefault()
            },
          }}
          onSuggestionsClearRequested={() => setSuggestions([])}
          onSuggestionsFetchRequested={value => getSuggestions(value.value)}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={() => true}
          suggestions={suggestions}
        />
      }
      {...props}
    />
  )
}

AutosuggestWrapper.propTypes = {
  data: PropTypes.array,
  errors: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  tooltip: PropTypes.string,
  touched: PropTypes.object,
}

AutosuggestWrapper.defaultProps = {
  data: [],
  errors: {},
  touched: {},
}

export default AutosuggestWrapper
