import React from "react"
import PropTypes from "prop-types"
import injectSheet from "react-jss"

const styles = {
  wrapper: {
    width: "100%",
    "@media (min-width: 769px)": {
      width: "80%",
      marginLeft: "5%",
    },
    "@media (min-width: 1024px)": {
      width: "60%",
      marginLeft: "10%",
    },
    "@media (min-width: 1240px)": {
      width: "50%",
      marginLeft: "15%",
    },
    "@media (min-width: 1440px)": {
      width: ({ width }) => (width === "wide" ? "60%" : "45%"),
      marginLeft: ({ width }) => (width === "wide" ? "10%" : "20%"),
    },
    "& button": {
      display: "flex",
      margin: "0 auto",
      alignItems: "center",
    },
  },
}

const FormLayout = ({ children, classes, ...props }) => (
  <div className={classes.wrapper} {...props}>
    {children}
  </div>
)

FormLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  width: PropTypes.oneOf(["normal", "wide"]),
}

FormLayout.defaultProps = {
  width: "normal",
}

export default injectSheet(styles)(FormLayout)
