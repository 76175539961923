import dynamic from "next/dynamic"

import FormLayout from "./FormLayout"
import TextField from "./TextField"
import RuianAutosuggest from "./RuianAutosuggest"
import PasswordInput from "./PasswordInput"
import Checkbox from "./Checkbox"
import Select from "./Select"
import Radio from "./Radio"
import DatePicker from "./DatePicker"
import CustomerAutosuggest from "./CustomerAutosuggest"
import Autosuggest from "./Autosuggest"
import FormRow from "./FormRow"
import CollapsiblePanel from "./CollapsiblePanel"

const Button = dynamic(() => import("@ipex/storybook").then(mod => mod.Button))
const ButtonIcon = dynamic(() => import("@ipex/storybook").then(mod => mod.ButtonIcon), {
  ssr: false,
  loading: () => null,
})
const Text = dynamic(() => import("@ipex/storybook").then(mod => mod.Text))
const RadioGroup = dynamic(() => import("@ipex/storybook").then(mod => mod.RadioGroup))
const CheckboxGroup = dynamic(() => import("@ipex/storybook").then(mod => mod.CheckboxGroup))
const UploadFileButton = dynamic(
  () => import("@ipex/storybook").then(mod => mod.UploadFileButton),
  {
    ssr: false,
    loading: () => null,
  },
)

export {
  Autosuggest,
  Button,
  ButtonIcon,
  CheckboxGroup,
  Checkbox,
  CustomerAutosuggest,
  DatePicker,
  FormLayout,
  PasswordInput,
  RadioGroup,
  Radio,
  RuianAutosuggest,
  Select,
  Text,
  TextField,
  UploadFileButton,
  FormRow,
  CollapsiblePanel,
}
