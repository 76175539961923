import React from "react"
import PropTypes from "prop-types"
import dynamic from "next/dynamic"

const Checkbox = dynamic(() => import("@ipex/storybook").then(mod => mod.Checkbox), {
  ssr: false,
  loading: () => null,
})
const FieldWrapper = dynamic(() => import("@ipex/storybook").then(mod => mod.Field))

const CheckboxWrapper = ({
  checked,
  disabled,
  errors,
  label,
  labelWidth,
  mainLabel,
  name,
  onChange,
  tooltip,
  touched,
  warningText,
  ...props
}) => (
  <FieldWrapper
    label={mainLabel}
    tooltip={tooltip}
    labelWidth={labelWidth}
    trigger="hover"
    error={touched[name] && errors[name]}
    component={
      <Checkbox
        checked={checked}
        disabled={disabled}
        id={name}
        label={label}
        name={name}
        onChange={onChange}
        type="checkbox"
        {...props}
      />
    }
    warningText={warningText}
    {...props}
  />
)

CheckboxWrapper.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  labelWidth: PropTypes.number,
  mainLabel: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  tooltip: PropTypes.any,
  touched: PropTypes.object,
  warningText: PropTypes.any,
}

CheckboxWrapper.defaultProps = {
  errors: {},
  touched: {},
}

export default CheckboxWrapper
