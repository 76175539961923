import React from "react"
import PropTypes from "prop-types"
import dynamic from "next/dynamic"

import { useWindowSize } from "../../../utils/hooks"

const FieldWrapper = dynamic(() => import("@ipex/storybook").then(mod => mod.Field))
const DatePicker = dynamic(() => import("@ipex/storybook").then(mod => mod.DatePicker))

const DatePickerWrapper = ({
  autoFocus,
  value,
  name,
  label,
  locale,
  onChange,
  errors,
  placeholder,
  touched,
  fieldType,
  tooltip,
  labelWidth,
  labelPosition,
  ...props
}) => {
  const size = useWindowSize()
  return (
    <FieldWrapper
      label={label}
      error={touched[name] && errors[name]}
      tooltip={tooltip}
      name={name}
      labelWidth={labelWidth}
      tooltipPlacement={size.width < 1024 ? "top-end" : "right-start"}
      component={
        <DatePicker
          data-cy={name}
          id={name}
          name={name}
          onChange={onChange}
          locale={locale}
          placeholder={placeholder}
          type="text"
          value={value}
          {...props}
        />
      }
      {...props}
    />
  )
}

DatePickerWrapper.propTypes = {
  autoFocus: PropTypes.bool,
  errors: PropTypes.object,
  fieldType: PropTypes.oneOf(["input", "textarea"]),
  label: PropTypes.string,
  locale: PropTypes.string,
  labelWidth: PropTypes.string,
  labelPosition: PropTypes.oneOf(["top", "middle"]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  touched: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

DatePickerWrapper.defaultProps = {
  fieldType: "input",
  errors: {},
  touched: {},
}

export default DatePickerWrapper
