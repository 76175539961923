import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import dynamic from "next/dynamic"
import { deburr } from "lodash"

import client from "../../../utils/apollo"
import customersQuery from "../../../graphql/customers/customersQuery.graphql"
import Loader from "../Loader"

const FieldWrapper = dynamic(() => import("@ipex/storybook").then(mod => mod.Field))
const Autosuggest = dynamic(() => import("@ipex/storybook").then(mod => mod.Autosuggest))

const CustomerAutosuggestWrapper = ({
  data,
  errors,
  isTransfer,
  label,
  name,
  onChange,
  onSelect,
  placeholder,
  renderSuggestionsContainer,
  simplified,
  tooltip,
  touched,
  value,
  isRequired,
  ...props
}) => {
  const [customers, setCustomers] = useState(data)
  const [suggestions, setSuggestions] = useState([])
  const [isInitialRender, setInitialRender] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let unmounted = false
    if (data.length && !unmounted) setIsLoading(false)
    if (!data.length) {
      client
        .query({
          query: customersQuery,
          variables: {
            pathBuilder: () => "customers?hidden=false",
          },
        })
        .then(data => {
          if (!unmounted) {
            if (!data.loading) setIsLoading(false)
            setCustomers(data.data.customers)
          }
        })
    }
    return () => {
      unmounted = true
    }
  }, [])

  const loadCustomers = (fulltext = "") => {
    const inputValue = deburr(fulltext.trim().toLowerCase())
    const suggestions =
      inputValue.length === 0 || (isTransfer && isInitialRender)
        ? customers.sort((a, b) => a.customerName.localeCompare(b.customerName))
        : customers
            .filter(customer =>
              deburr(customer.customerName)
                .toLowerCase()
                .includes(inputValue),
            )
            .sort((a, b) => a.customerName.localeCompare(b.customerName))
    setSuggestions(suggestions)
    isInitialRender && setInitialRender(false)
  }

  const getSuggestionValue = suggestion => {
    if (!suggestion.customerName) return ""
    onSelect(suggestion)
    return getSuggestionText(suggestion)
  }

  const getSuggestionText = suggestion =>
    `${suggestion.customerName}${suggestion.street ? `, ${suggestion.street}` : ""}${
      suggestion.city ? `, ${suggestion.city}` : ""
    }${suggestion.zipCode ? `, ${suggestion.zipCode}` : ""}`

  const renderSuggestion = suggestion =>
    suggestion.text ? (
      <div style={{ cursor: "default" }}>{suggestion.text}</div>
    ) : (
      <div>{getSuggestionText(suggestion)}</div>
    )

  return (
    <FieldWrapper
      label={label}
      error={touched[name] && errors[name]}
      name={name}
      component={
        <Autosuggest
          getSuggestionValue={getSuggestionValue}
          isRequired={isRequired}
          error={touched[name] && !!errors[name]}
          inputProps={{
            ...props,
            placeholder,
            value,
            id: name,
            onChange: (e, { newValue }) => {
              onChange(name, newValue)
            },
            onKeyDown: e => {
              if (e.keyCode === 13) e.preventDefault()
            },
          }}
          renderInputComponent={inputProps => (
            <div style={{ display: "flex", minWidth: 250 }}>
              <input {...inputProps} />
              {isLoading && (
                <div className="input-group-addon" style={{ width: 25, padding: "7px 5px" }}>
                  <Loader isSmall />
                </div>
              )}
            </div>
          )}
          onSuggestionsClearRequested={() => setSuggestions([])}
          onSuggestionsFetchRequested={value => loadCustomers(value.value)}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          shouldRenderSuggestions={() => true}
          suggestions={suggestions}
        />
      }
      {...props}
    />
  )
}

CustomerAutosuggestWrapper.propTypes = {
  data: PropTypes.array,
  errors: PropTypes.object,
  isTransfer: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  renderSuggestionsContainer: PropTypes.func,
  simplified: PropTypes.bool,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.string,
  touched: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CustomerAutosuggestWrapper.defaultProps = {
  data: [],
  errors: {},
  touched: {},
}

export default CustomerAutosuggestWrapper
