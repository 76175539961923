/** Created by Tomáš Korec on 2019/10/22 */

import Router from "next/router"
import client from "../utils/apollo"

import { hasSomeRole, parsePermissions } from "./rolesManager"
import { parseModules } from "./moduleManager"
import { Cookies } from "./cookiesManager"

import freshRightsQuery from "../graphql/login/freshRightsQuery.graphql"
import providerSettingsQuery from "../graphql/providers/providerSettingsQuery.graphql"
import providerSettingsSqlQuery from "../graphql/providers/providerSettingsSql.graphql"
import accountDetailQuery from "../graphql/accounts/accountDetailQuery.graphql"
import operatorLoginMutation from "../graphql/login/operatorLoginMutation.graphql"
import loginMutation from "../graphql/login/loginMutation.graphql"
import rightsQuery from "../graphql/login/rightsQuery.graphql"

/**
 * AUTH MANAGEMENT
 */
export const resetAuth = () => {
  clearTimeout(logoutTimeout)
  client.resetStore()
  Cookies.removeAuth()
  Cookies.removeUser()
  Cookies.removeProvider()
}

export const clearProviderMask = () => {
  Cookies.removeProvider()
  const user = Cookies.getUser()
  delete user.hasMask
  Cookies.storeUser(user)
}

const setUser = ({ login, name, permissions }) => {
  const user = {
    login,
    name,
    roles: permissions ? parsePermissions(permissions) : {},
  }

  if (permissions.includes("operator")) {
    user.operator = true
  }
  if (hasSomeRole(user)) {
    Cookies.storeUser(user, { expires: new Date(Cookies.getAuth().expire) })
    return user
  }
}

const setAuth = ({ accessToken, expire }) => {
  Cookies.storeAuth({ token: accessToken, expire }, { expires: new Date(expire) })
}

const setProvider = (provider, account, settings, settingsSql, asOperator) => {
  const providerData = {
    id: provider.id,
    name: provider.name,
    contactEmail: provider.contactEmail,
    modules: provider.allowedModules ? parseModules(provider.allowedModules) : {},
    account,
    settings: { creditExpirationDate: settingsSql.exp_default_dnu },
  }
  Cookies.storeProvider(providerData, { expires: new Date(Cookies.getAuth().expire) })
  if (asOperator) {
    Cookies.storeUser({ ...Cookies.getUser(), hasMask: true })
  }
  return providerData
}

const setAccount = account => {
  const provider = Cookies.getProvider()
  Cookies.storeProvider({ ...provider, account })
}

export const getToken = () => {
  const auth = Cookies.getAuth()
  return auth && auth.token
}

/**
 * AUTH UTILS
 */
export const isBrowser = () => typeof window === "object"

export const isServer = () => typeof window === "undefined"

export const isB2BExported = () =>
  isBrowser() && window.localStorage.getItem("B2BExported") === "true"

export const isAuthenticated = cookies => {
  if (isBrowser()) {
    // communicator
    const { anvilToken, anvilTokenExpire } = window.localStorage
    if (anvilToken) {
      return !!(anvilToken && anvilTokenExpire && new Date(parseInt(anvilTokenExpire)) > new Date())
    }
    // b2b browser
    const auth = Cookies.getAuth()
    if (auth) {
      const { token, expire } = auth
      return !!(token && expire && new Date(expire) > new Date())
    }
  }
  // b2b server
  else if (cookies && cookies.auth) {
    const { token, expire } = cookies.auth
    return !!(token && expire && new Date(expire) > new Date())
  }
  return false
}

const hasAccount = ({ account }) => {
  if (!account) return false
  const { name, email } = account
  return !!(email && name)
}

let logoutTimeout = null

export const setAutoLogout = (expire, alertLogout, clearAlerts) => {
  const currentTime = new Date()
  const expireTime = new Date(expire)
  const timeToWarning = expireTime.getTime() - currentTime.getTime() - 6 * 60 * 1000
  const timeToExpire = 5 * 60 * 1000

  logoutTimeout && clearTimeout(logoutTimeout)
  logoutTimeout = setTimeout(() => {
    alertLogout()
    logoutTimeout = setTimeout(() => {
      clearAlerts()
      logout()
    }, timeToExpire)
  }, timeToWarning)
}

/**
 * NAVIGATION FUNCTIONS
 */
const initialRedirect = nextPath => {
  const user = Cookies.getUser()
  const provider = Cookies.getProvider()
  const isOperator = user.operator
  if (!isOperator && !hasAccount(provider) && user.login) {
    return {
      pathname: "/accountForm",
      query: { login: encodeURIComponent(user.login) },
    }
  }
  if (isOperator) {
    return provider ? { pathname: "/dashboard" } : { pathname: "/providers" }
  }
  if (hasSomeRole(user)) {
    return nextPath ? decodeURIComponent(nextPath) : { pathname: "/dashboard" }
  }
  return { pathname: "/support" }
}

export const homeRedirect = userCookie => {
  const user = userCookie || Cookies.getUser()
  if (hasSomeRole(user))
    if (user.operator && !user.hasMask) {
      Router.push({ pathname: "/providers" })
    } else {
      Router.push({ pathname: "/dashboard" })
    }
  else {
    Router.push({ pathname: "/support" })
  }
}

export const loginRedirect = () =>
  Router.pathname === "/"
    ? Router.push({ pathname: "/login" })
    : Router.push({
        pathname: "/login",
        query: { nextPath: Router.asPath },
      })

/**
 * NETWORK FUNCTIONS
 */
export const login = async ({ login, password }, query, { alertLogout, clearAlerts }) => {
  try {
    await client.resetStore()
    const {
      data: { account },
    } = await client.mutate({
      mutation: loginMutation,
      variables: {
        input: {
          username: login,
          password,
        },
      },
    })
    setAuth(account)

    if (account && account.expire) {
      setAutoLogout(account.expire, alertLogout, clearAlerts)
    }

    const {
      data: { rights },
    } = await client.query({
      query: rightsQuery,
    })
    const user = setUser(rights)
    if (!user.operator) {
      const {
        data: { accountDetail },
      } = await client.query({
        query: accountDetailQuery,
        variables: {
          login: encodeURIComponent(login),
        },
      })
      const {
        data: { providerSettings },
      } = await client.query({
        query: providerSettingsQuery,
      })
      const {
        data: { providerSettingsSql },
      } = await client.query({
        query: providerSettingsSqlQuery,
      })
      setProvider(rights.provider, accountDetail[0], providerSettings, providerSettingsSql)
    }
    Router.push(initialRedirect(query.nextPath))
  } catch (err) {
    if (
      err &&
      err.networkError &&
      err.networkError.result &&
      err.networkError.result.message === "User does not have any permissions"
    ) {
      Router.push(
        query.nextPath && query.nextPath.includes("omnichannel")
          ? decodeURIComponent(query.nextPath)
          : "/support",
      )

      const now = new Date()
      setAuth({
        accessToken: "unauthorized",
        expire: now.setTime(now.getTime() + 300000), // 5 minutes expiration
      })
    } else {
      resetAuth()
      throw err
    }
  }
}

export const logout = () => {
  resetAuth()
  Router.push({ pathname: "/login" })
}

export const operatorLogin = async (providerId, searchValue, customRedirect) => {
  try {
    await client.resetStore()
    await client.mutate({
      mutation: operatorLoginMutation,
      variables: { providerId },
    })
    const freshRights = await client.query({
      query: freshRightsQuery,
    })
    const {
      data: { providerSettings },
    } = await client.query({
      query: providerSettingsQuery,
    })
    const {
      data: { providerSettingsSql },
    } = await client.query({
      query: providerSettingsSqlQuery,
    })
    setProvider(
      freshRights.data.rights.provider,
      undefined,
      providerSettings,
      providerSettingsSql,
      true,
    )
    if (customRedirect) {
      Router.push(customRedirect)
    } else {
      searchValue
        ? Router.push({
            pathname: "/fulltext",
            query: { searchValue: searchValue.toString(), onlyService: "true" },
          })
        : Router.push({ pathname: "/dashboard" })
    }
  } catch (err) {
    console.error("Failed to login as operator", err)
  }
}

export const operatorLogout = () => {
  clearProviderMask()
  Router.push({ pathname: "/providers" })
}

export const reloadAccount = async login => {
  try {
    const {
      data: { accountDetail },
    } = await client.query({
      query: accountDetailQuery,
      variables: {
        login: encodeURIComponent(login),
      },
    })
    setAccount(accountDetail)
  } catch (err) {
    console.error("Failed to reload account detail", err)
  }
}
