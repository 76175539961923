import getConfig from "next/config"
import { ApolloClient } from "apollo-client"
import { RestLink } from "apollo-link-rest"
import { InMemoryCache } from "apollo-cache-inmemory"
import fetch from "isomorphic-fetch"

import { getEnv, getFlowApiUrl, getPbxApiUrl, getRestApiV2Url } from "./environmentUtils"
import { isBrowser, getToken } from "../core"

// Allow server-side
global.Headers = global.Headers || require("fetch-headers")
const { API_URL } = getConfig().publicRuntimeConfig

const customFetch = (request, options) => {
  options = options || {}
  options.headers = {
    "Content-type": "application/json",
    Accept: "application/json",
    Authorization:
      isBrowser() && window.localStorage.anvilToken
        ? "Bearer " + window.localStorage.anvilToken
        : getToken(),
    ...options.headers,
  }
  return new Promise((resolve, reject) => {
    fetch(request, options)
      .then(response => {
        if (response.status === 404) {
          return response.json().then(responseJson => {
            reject(new Error(responseJson.message))
          })
        }
        resolve(response)
      })
      .catch(error => error)
  })
}

export const actualURL =
  API_URL ||
  (typeof window !== "undefined" && window.localStorage.API_URL) ||
  "https://restapi-devel.ipex.cz/v1/"

const restLink = new RestLink({
  uri: actualURL,
  endpoints: {
    "": actualURL, // default endpoint
    v1: "/v1",
    v2: getRestApiV2Url(getEnv(actualURL)),
    version1: "https://api.example.com/v1",
    version2: "https://api.example.com/v2",
    b2b: actualURL,
    flow: getFlowApiUrl(getEnv(actualURL)),
    swapi: "https://swapi.co/api/",
    pbxapi: {
      uri: getPbxApiUrl(getEnv(actualURL)),
      responseTransformer: async response => response.text(),
    },
    ctu: {
      uri: actualURL,
      responseTransformer: async response => response.json().then(({ data }) => data),
    },
  },
  credentials: "omit", // without credentials it do not work in safari
  customFetch,
})

const defaultOptions = {
  query: {
    fetchPolicy: "network-only",
  },
  watchQuery: {
    fetchPolicy: "cache-and-network",
  },
}

// Configure the ApolloClient with the default cache and RestLink
const client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
  defaultOptions,
})

export default client
