import React, { isValidElement } from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"

import { AlertType } from "../../core/alerts/AlertsManager"
import Icon from "../common/Icon"

const alertStyle = {
  [AlertType.SUCCESS]: theme => ({
    backgroundColor: theme.color.green650,
    color: theme.color.grey100,
  }),
  [AlertType.ERROR]: theme => ({
    backgroundColor: theme.color.red650,
    color: theme.color.grey100,
  }),
  [AlertType.WARNING]: theme => ({
    backgroundColor: theme.color.yellow,
    color: theme.color.grey600,
  }),
  [AlertType.INFO]: theme => ({
    backgroundColor: theme.color.white,
    color: theme.color.grey600,
  }),
}

const useStyles = createUseStyles(theme => ({
  alert: ({ type }) => ({
    position: "relative",
    display: "inline-block",
    borderRadius: "0.25rem",
    padding: "0.75rem 1rem",
    marginBottom: "1rem",
    maxWidth: "60rem",
    boxShadow: "0 0 8px 1px rgba(0, 0, 0, 0.33)",
    ...alertStyle[type](theme),
  }),
  content: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    marginRight: "1.5rem",
  },
  icon: {
    flex: "0 0 auto",
    lineHeight: 0,
    marginRight: "1.25rem",
    fontSize: "1.5rem",
  },
  message: {
    flex: "1 1 auto",
    fontSize: "0.875rem",
    textAlign: "left",
  },
  close: {
    position: "absolute",
    cursor: "pointer",
    right: 0,
    top: 0,
    padding: "0.625rem",
    lineHeight: 1,
    fontSize: "1rem",
  },
}))
const alertIcon = {
  [AlertType.SUCCESS]: "check-circle",
  [AlertType.ERROR]: "x-circle",
  [AlertType.WARNING]: "alert-circle",
  [AlertType.INFO]: "info",
}

const Alert = ({ id, message, type, onClose, ...props }) => {
  const classes = useStyles({ type })

  let content = message.toString()
  if (isValidElement(message)) content = message

  return (
    <div id={id} className={classes.alert} {...props}>
      <div className={classes.content}>
        <div className={classes.icon}>
          <Icon iconType={alertIcon[type]} />
        </div>
        <div className={classes.message}>
          <p>{content}</p>
        </div>
      </div>
      {onClose && (
        <span className={classes.close} onClick={onClose}>
          <Icon iconType="x" />
        </span>
      )}
    </div>
  )
}

const sharedProps = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
}

Alert.propTypes = {
  ...sharedProps,
  type: PropTypes.oneOf(["SUCCESS", "ERROR", "INFO", "WARNING"]).isRequired,
}

export const SuccessAlert = props => <Alert type={AlertType.SUCCESS} {...props} />
export const ErrorAlert = props => <Alert type={AlertType.ERROR} {...props} />
export const InfoAlert = props => <Alert type={AlertType.INFO} {...props} />
export const WarningAlert = props => <Alert type={AlertType.WARNING} {...props} />

SuccessAlert.propTypes = sharedProps
ErrorAlert.propTypes = sharedProps
InfoAlert.propTypes = sharedProps
WarningAlert.propTypes = sharedProps

export default Alert
