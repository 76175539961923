/** Created by Lenka Hubáčková on 20/08/2019. */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import PasswordPolicy from "voipex-password-policy"
import dynamic from "next/dynamic"

import { useI18n } from "../../../internationalization/i18n"

const FieldWrapper = dynamic(() => import("@ipex/storybook").then(mod => mod.Field))
const Input = dynamic(() => import("@ipex/storybook").then(mod => mod.Input))
const PasswordTooltip = dynamic(() => import("@ipex/storybook").then(mod => mod.PasswordTooltip))
const PasswordProgress = dynamic(() => import("@ipex/storybook").then(mod => mod.PasswordProgress))

const policy = new PasswordPolicy()
policy.defaultPolicy = "user"

const voipexPasswordValidation = password => {
  const isValid = policy.validate(password)
  const { errors } = policy
  return { isValid, errors }
}

const progressCalculator = validation => {
  const minTimeToCrack = validation.errors.find(item => item.validator === "MinimumTimeToCrack")
  return minTimeToCrack ? (minTimeToCrack.actual / 14) * 100 : 100
}

const validatePassword = (passwordValidation, password, isRequired) =>
  !isRequired && !password ? { isValid: true, errors: [] } : passwordValidation(password)

const PasswordInput = ({
  autoFocus,
  errors,
  fieldType,
  hideProgressBar,
  isRequired,
  label,
  message,
  name,
  onChange,
  passwordValidation,
  placeholder,
  progressLabel,
  touched,
  validPasswordHandler,
  value,
  visibility,
  ...props
}) => {
  const [{ progress, passwordErrors }, setPasswordState] = useState({
    progress: progressCalculator(validatePassword(passwordValidation, value, isRequired)),
    passwordErrors: validatePassword(passwordValidation, value, isRequired).errors,
  })
  const {
    messages: {
      components: { passwordTooltip: msg },
    },
  } = useI18n()
  useEffect(() => {
    const validation = validatePassword(passwordValidation, value, isRequired)
    const progress = progressCalculator(validation)
    setPasswordState({ progress, passwordErrors: validation.errors })
    validPasswordHandler &&
      (progress < 100 || !!validation.errors.length
        ? validPasswordHandler(false)
        : validPasswordHandler(true))
  }, [value])
  const defaultMessage = {
    title1: msg.title1,
    title2: msg.title2,
    length: msg.length,
    capital: msg.capital,
    number: msg.number,
    letter: msg.letter,
    specials: [msg.special1, msg.special2, msg.special3, msg.special4, msg.special5, msg.special6],
  }
  return (
    <>
      <FieldWrapper
        component={
          <Input
            autoFocus={autoFocus}
            data-cy={name}
            error={touched[name] && errors[name]}
            id={name}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            isRequired={isRequired}
            {...props}
          />
        }
        label={label}
        tooltip={<PasswordTooltip errors={passwordErrors} message={message || defaultMessage} />}
        error={touched[name] && errors[name]}
        trigger="focus"
        name={name}
        {...props}
      />
      {value && !hideProgressBar && (
        <FieldWrapper
          component={
            <PasswordProgress
              progress={progress}
              hasErrors={passwordErrors && !!passwordErrors.length}
            />
          }
          label={progressLabel}
        />
      )}
    </>
  )
}

PasswordInput.propTypes = {
  autoFocus: PropTypes.bool,
  errors: PropTypes.object,
  fieldType: PropTypes.string,
  hideProgressBar: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  message: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  passwordValidation: PropTypes.func,
  placeholder: PropTypes.string,
  progressLabel: PropTypes.string,
  smallInput: PropTypes.bool,
  touched: PropTypes.object,
  validPasswordHandler: PropTypes.func,
  value: PropTypes.string,
  visibility: PropTypes.bool,
}

PasswordInput.defaultProps = {
  hideProgressBar: false,
  label: "",
  passwordValidation: voipexPasswordValidation,
  progressLabel: "",
  value: "",
  visibility: true,
  isRequired: false,
}

export default PasswordInput
