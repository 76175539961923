/** Created by Tomáš Korec on 2019/10/22 */

import React from "react"
import Cookie from "js-cookie"
import nextCookies from "next-cookies"

const AUTH_KEY = "auth"
const USER_KEY = "user"
const PROVIDER_KEY = "provider"
export const LANG_KEY = "lang"

export const CookieContext = React.createContext({})

export const getCookies = ctx => {
  const { auth, user, provider, lang, contactsColumns } = nextCookies(ctx)
  return {
    auth: auth && JSON.parse(auth),
    user: user && JSON.parse(user),
    provider: provider && JSON.parse(provider),
    lang,
    contactsColumns,
  }
}

export const storeLanguage = language => Cookie.set(LANG_KEY, language)

const storeAuth = (auth, options) => Cookie.set(AUTH_KEY, auth, options)
const storeUser = (user, options) => Cookie.set(USER_KEY, user, options)
const storeProvider = (provider, options) => Cookie.set(PROVIDER_KEY, provider, options)

const getAuth = () => Cookie.getJSON(AUTH_KEY)
const getUser = () => Cookie.getJSON(USER_KEY)
const getProvider = () => Cookie.getJSON(PROVIDER_KEY)

const removeAuth = () => Cookie.remove(AUTH_KEY)
const removeUser = () => Cookie.remove(USER_KEY)
const removeProvider = () => Cookie.remove(PROVIDER_KEY)

export const Cookies = {
  storeAuth,
  storeUser,
  storeProvider,
  getAuth,
  getUser,
  getProvider,
  removeAuth,
  removeUser,
  removeProvider,
}
