import { useState, useEffect, useRef } from "react"
import Chart from "chart.js"

import { isBrowser } from "../core"

export const useB2BExported = () => {
  const [isExported, toggleExported] = useState(false)
  useEffect(() => {
    isBrowser() && window.localStorage.getItem("B2BExported") === "true" && toggleExported(true)
    return () => {}
  }, [])
  return isExported
}

export const useComponentVisible = initialIsVisible => {
  const [isComponentVisible, setComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => document.removeEventListener("click", handleClickOutside, true)
  })

  return [isComponentVisible, setComponentVisible, ref]
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useTimeout = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setTimeout(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const useChart = chartOptions => {
  const canvasRef = useRef()

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d")
    const myChart = new Chart(ctx, chartOptions)
    return () => myChart.destroy()
  }, [chartOptions])
  return canvasRef
}

export const useTime = (refreshCycle = 250) => {
  const [now, setNow] = useState(getTime())

  useEffect(() => {
    const intervalId = setInterval(() => setNow(getTime()), refreshCycle)

    // Cleanup interval
    return () => clearInterval(intervalId)

    // Specify dependencies for useEffect
  }, [refreshCycle])

  return now
}

export const getTime = () => new Date().getTime()

export const useCountdown = targetedTime => targetedTime - useTime(100)

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({})

  useEffect(() => {
    function getSize() {
      return {
        width: isBrowser ? window.innerWidth : undefined,
        height: isBrowser ? window.innerHeight : undefined,
      }
    }

    function handleResize() {
      setWindowSize(getSize())
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      clearTimeout(handler)
    }
  }, [value])

  return debouncedValue
}

export const useClick = (ref, action, dependable, opts = []) => {
  const callback = useRef(action)

  useEffect(() => {
    callback.current = action
  })

  useEffect(() => {
    const handleClick = event => {
      if (ref && ref.current && ref.current === event.target) {
        return callback.current(event)
      }
    }
    // If 'dependable' arg is not set, always register 'click' event
    if (dependable === undefined) {
      document.addEventListener("click", handleClick)
    } else if (dependable) {
      document.addEventListener("click", handleClick)
    }

    return () => document.removeEventListener("click", handleClick)
  }, [dependable, ...opts])
}
