import React, { useState } from "react"
import PropTypes from "prop-types"
import injectSheet from "react-jss"

import { useI18n } from "../../../internationalization/i18n"
import Icon from "../Icon"

const styles = theme => ({
  wrapper: {
    marginBottom: "1rem",
  },
  heading: {
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    "& svg": {
      marginLeft: 5,
      color: theme.color.theme500,
    },
  },
  collapsed: {
    display: "none",
  },
})

const CollapsiblePanel = ({ children, visibility, text, classes, initiallyOpen, ...props }) => {
  const {
    messages: {
      components: { collapsiblePanel: msg },
    },
  } = useI18n()
  const [open, toggleOpen] = useState(initiallyOpen)

  return visibility ? (
    <div className={classes.wrapper} {...props}>
      <div className={classes.heading} onClick={() => toggleOpen(!open)}>
        <span>
          {text || (open ? msg.openLabel : msg.closeLabel)}
          {<Icon iconType={open ? "chevron-up" : "chevron-down"} />}
        </span>
      </div>
      <div className={open ? "" : classes.collapsed}>{children}</div>
    </div>
  ) : null
}

CollapsiblePanel.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.any,
  visibility: PropTypes.bool,
  initiallyOpen: PropTypes.bool,
  text: PropTypes.string,
}

CollapsiblePanel.defaultProps = {
  visibility: true,
  initiallyOpen: false,
}
export default injectSheet(styles)(CollapsiblePanel)
