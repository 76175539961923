import React from "react"
import PropTypes from "prop-types"
import NextLink from "next/link"

const Link = ({ to, children, onClick, style, ...props }) => {
  const href = to && to.startsWith("/") ? to : "/" + to
  return (
    <NextLink href={href} {...props}>
      <a onClick={onClick} style={style}>
        {children}
      </a>
    </NextLink>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func,
  style: PropTypes.object,
}

export default Link
