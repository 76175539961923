/** Created by Tomáš Korec on 2020-1-27 */

import React from "react"
import { createUseStyles } from "react-jss"

import Icon from "../Icon"

const useStyles = createUseStyles(theme => ({
  chevron: {
    display: "flex",
    fontSize: "0.875rem",
    margin: "0 0.875rem",
    color: theme.color.grey400,
  },
}))

export default () => {
  const classes = useStyles()
  return (
    <span className={classes.chevron}>
      <Icon iconType="chevron-right" size={20} />
    </span>
  )
}
