/**
 * Created by Filip Drgoň on 26/04/2018.
 */
import React from "react"
import PropTypes from "prop-types"
import Link from "../../Link"
import { useI18n } from "../../../../internationalization/i18n"

export const CustomerDashboardBreadcrumb = ({ customer, noLink }) =>
  noLink ? (
    <span>{customer.customerName}</span>
  ) : (
    <Link to={`/customerDashboard?customerId=${customer.id}`}>{customer.customerName}</Link>
  )

CustomerDashboardBreadcrumb.propTypes = {
  customer: PropTypes.object.isRequired,
  noLink: PropTypes.bool,
}

export const CustomerSettingsBreadcrumb = () => {
  const {
    messages: {
      components: { breadcrumbs: msg },
    },
  } = useI18n()
  return <span>{msg.customerSettings}</span>
}
