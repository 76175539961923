import React from "react"
import PropTypes from "prop-types"
import dynamic from "next/dynamic"
import { getIn } from "formik"

import { useI18n } from "../../../internationalization/i18n"

const FieldWrapper = dynamic(() => import("@ipex/storybook").then(mod => mod.Field))
const SelectFilter = dynamic(() => import("@ipex/storybook").then(mod => mod.SelectFilter))

const SelectWraper = ({
  disabled,
  errors,
  label,
  name,
  onChange,
  options,
  placeholder,
  tooltip,
  touched,
  value,
  zeroOption,
  warningText,
  isSearchable,
  ...props
}) => {
  const {
    messages: {
      components: { select: msg },
    },
  } = useI18n()
  return (
    <FieldWrapper
      tooltip={tooltip}
      label={label}
      error={getIn(touched, name) && getIn(errors, name)}
      name={name}
      component={
        <SelectFilter
          data-cy={name}
          disabled={disabled}
          value={value}
          id={name}
          name={name}
          onChange={onChange}
          options={
            zeroOption ? [{ label: placeholder || msg.zeroOption, value: "" }, ...options] : options
          }
          placeholder={placeholder || msg.zeroOption}
          error={getIn(touched, name) && !!getIn(errors, name)}
          isSearchable={isSearchable}
          {...props}
        />
      }
      warningText={warningText}
      {...props}
    />
  )
}

SelectWraper.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  tooltip: PropTypes.any,
  touched: PropTypes.object,
  value: PropTypes.object,
  zeroOption: PropTypes.bool,
  warningText: PropTypes.string,
  isSearchable: PropTypes.bool,
}

SelectWraper.defaultProps = {
  errors: {},
  touched: {},
  isSearchable: false,
}

export default SelectWraper
