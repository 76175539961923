import React from "react"
import PropTypes from "prop-types"
import injectSheet from "react-jss"

const styles = {
  formRow: {
    width: "100%",
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "minmax(100px, 30%) 70%",
  },
  formRowElem: {
    display: "flex",
  },
}

const FormRow = ({ label, children, classes, ...props }) => (
  <div className={classes.formRow} {...props}>
    <div className={classes.formRowElem}>{label}</div>
    <div className={classes.formRowElem}>{children}</div>
  </div>
)

FormRow.propTypes = {
  label: PropTypes.node,
  classes: PropTypes.object,
  children: PropTypes.node,
}

export default injectSheet(styles)(FormRow)
