import React, { useState } from "react"
import PropTypes from "prop-types"
import dynamic from "next/dynamic"

import client from "../../../utils/apollo"
import ruianQuery from "../../../graphql/ruianQuery.graphql"
const FieldWrapper = dynamic(() => import("@ipex/storybook").then(mod => mod.Field))
const Autosuggest = dynamic(() => import("@ipex/storybook").then(mod => mod.Autosuggest))

const RuianAutosuggestWrapper = ({
  autoFocus,
  errors,
  label,
  name,
  onChange,
  onSelect,
  placeholder,
  touched,
  value,
  isRequired,
  ...props
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [currentSuggestion, setCurrentSuggestion] = useState("")

  const getSuggestionValue = suggestion => {
    onSelect(suggestion)
    setCurrentSuggestion(suggestion)
    return suggestion.text
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    if (parseInt(value.length) > 3) {
      setRuian(value)
    }
  }

  const setRuian = fulltext => {
    const fulltextValue = fulltext.replace("/", " ")
    client
      .query({
        query: ruianQuery,
        fetchPolicy: "no-cache",
        variables: { pathBuilder: () => `ruian/${fulltextValue}` },
        skip: ({ fulltextValue }) => !fulltextValue,
      })
      .then(data => setSuggestions(data.data.ruian))
  }

  const renderSuggestion = suggestion => <span>{suggestion.text}</span>

  return (
    <FieldWrapper
      label={label}
      error={touched[name] && errors[name]}
      name={name}
      component={
        <Autosuggest
          inputProps={{
            ...props,
            placeholder,
            value,
            id: name,
            onChange: (e, { newValue }) => {
              onChange(name, newValue)
            },
            onBlur: e => {
              if (!currentSuggestion || currentSuggestion.text !== e.target.value) {
                onChange(name, "")
                props.onBlur(e)
              }
            },
            onKeyDown: e => {
              if (e.keyCode === 13) e.preventDefault()
            },
          }}
          isRequired={isRequired}
          error={touched[name] && !!errors[name]}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => setSuggestions([])}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          suggestions={suggestions}
        />
      }
      {...props}
    />
  )
}

RuianAutosuggestWrapper.propTypes = {
  autoFocus: PropTypes.bool,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  touched: PropTypes.object,
  isRequired: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

RuianAutosuggestWrapper.defaultProps = {
  errors: {},
  touched: {},
}

export default RuianAutosuggestWrapper
