/** Created by Tomáš Korec on 2020-1-27 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"

import { useTimeout } from "../../utils/hooks"
import { useI18n } from "../../internationalization/i18n"
import Icon from "./Icon"

const useStyles = createUseStyles(theme => ({
  loader: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: theme.color.black,
    display: ({ isSmall }) => isSmall && "inline-block",
    paddingTop: ({ isSmall }) => (isSmall ? 0 : 25),
    "& svg": {
      animation: "spin 1.5s linear infinite",
    },
  },
  widgetLoader: {
    extends: "loader",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "3em",
    color: "#333",
    height: "calc(100% - 3.25rem)",
    "& svg": {
      animation: "spin 1.5s linear infinite",
    },
  },
}))

const Loader = ({ isSmall, className, isWidget, ...props }) => {
  const msg = useI18n().messages.common
  let [visible, setVisible] = useState(false)

  const classes = useStyles({ isSmall })

  useTimeout(() => {
    setVisible(true)
  }, 500)

  if (!visible) return null

  return (
    <div className={isWidget ? classes.widgetLoader : classes.loader} {...props}>
      <Icon iconType="spinner" style={{ marginRight: isSmall ? 0 : 15 }} size={25} />
      {!isSmall && `${msg.loading}...`}
    </div>
  )
}

export default Loader

Loader.defaultProps = {
  isWidget: false,
}

Loader.propTypes = {
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  isWidget: PropTypes.bool,
}
