import React, { useContext, useReducer } from "react"
import PropTypes from "prop-types"

import { alertReducer, Action } from "./AlertsReducer"

export const AlertType = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INFO: "INFO",
  WARNING: "WARNING",
}

const defaultContext = {
  putSuccessAlert: function(id, message, closeAfter) {},
  putErrorAlert: function(id, message, closeAfter) {},
  putInfoAlert: function(id, message, closeAfter) {},
  putWarningAlert: function(id, message, closeAfter) {},
  removeAlert: function(id) {},
  clearAlerts: () => {},
}

export const AlertsContext = React.createContext(defaultContext)

export const AlertsProvider = ({ children }) => {
  const [alerts, dispatch] = useReducer(alertReducer, [])

  const removeAlert = id => {
    dispatch({ type: Action.REMOVE, id })
  }

  const clearAlerts = () => {
    alerts.forEach(alert => alert.timeout && clearTimeout(alert.timeout))
    dispatch({ type: Action.CLEAR })
  }

  const putAlert = (id, message, type, closeAfter) => {
    const alert = { id, message, type }

    if (closeAfter) {
      alert.timeout = setTimeout(() => removeAlert(alert.id), closeAfter)
    }
    dispatch({ type: Action.PUT, alert })
  }

  const putSuccessAlert = (id, message, closeAfter) =>
    putAlert(id, message, AlertType.SUCCESS, closeAfter)
  const putErrorAlert = (id, message, closeAfter) =>
    putAlert(id, message, AlertType.ERROR, closeAfter)
  const putInfoAlert = (id, message, closeAfter) =>
    putAlert(id, message, AlertType.INFO, closeAfter)
  const putWarningAlert = (id, message, closeAfter) =>
    putAlert(id, message, AlertType.WARNING, closeAfter)

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        putSuccessAlert,
        putErrorAlert,
        putInfoAlert,
        putWarningAlert,
        removeAlert,
        clearAlerts,
      }}
    >
      {children}
    </AlertsContext.Provider>
  )
}

AlertsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export const useAlerts = componentId => {
  const { putSuccessAlert, putErrorAlert, putInfoAlert, putWarningAlert, clearAlerts } = useContext(
    AlertsContext,
  )

  const handleError = (error, customMessage) => {
    const errorId = `${componentId}_networkError`
    if (!error) return
    const formattedError =
      error.networkError && error.networkError.result
        ? `Error code ${error.networkError.statusCode}: ${error.networkError.result.message}`
        : error.message
    console.error(`${errorId}: ${formattedError}`)
    putErrorAlert(errorId, customMessage || formattedError)
    return formattedError
  }

  const alertSuccess = (id, message, closeAfter) =>
    putSuccessAlert(`${componentId}-${id}_success`, message, closeAfter)

  const alertError = (id, message, closeAfter) =>
    putErrorAlert(`${componentId}-${id}_error`, message, closeAfter)

  const alertWarning = (id, message, closeAfter) =>
    putWarningAlert(`${componentId}-${id}_warning`, message, closeAfter)

  const alertInfo = (id, message, closeAfter) =>
    putInfoAlert(`${componentId}-${id}_info`, message, closeAfter)

  return { handleError, alertSuccess, alertError, alertWarning, alertInfo, clearAlerts }
}
