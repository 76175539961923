import React from "react"
import PropTypes from "prop-types"
import dynamic from "next/dynamic"
const Radio = dynamic(() => import("@ipex/storybook").then(mod => mod.Radio), {
  ssr: false,
  loading: () => null,
})

const RadioWrapper = ({ disabled, label, name, onChange, value, ...props }) => (
  <Radio
    checked={name === value}
    disabled={disabled}
    id={name}
    label={label}
    onChange={onChange}
    selectedOption={value}
    {...props}
  />
)

RadioWrapper.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

RadioWrapper.defaultProps = {
  disabled: false,
  errors: {},
  onChange: () => {},
}

export default RadioWrapper
