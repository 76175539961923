import React from "react"
import PropTypes from "prop-types"
import { getIn } from "formik"
import dynamic from "next/dynamic"

const FieldWrapper = dynamic(() => import("@ipex/storybook").then(mod => mod.Field))
const Input = dynamic(() => import("@ipex/storybook").then(mod => mod.Input))
const Textarea = dynamic(() => import("@ipex/storybook").then(mod => mod.Textarea))

const TextField = ({
  autoFocus,
  value,
  name,
  label,
  onChange,
  errors,
  placeholder,
  isRequired,
  touched,
  fieldType,
  tooltip,
  labelWidth,
  labelPosition,
  validate,
  counterMaxVal,
  ...props
}) => (
  <FieldWrapper
    label={label}
    error={(getIn(touched, name) && getIn(errors, name)) || validate(value)}
    name={name}
    tooltip={tooltip}
    labelWidth={labelWidth}
    counterMaxVal={counterMaxVal}
    value={value}
    labelPosition={labelPosition}
    component={
      fieldType === "textarea" ? (
        <Textarea
          autoFocus={autoFocus}
          data-cy={name}
          id={name}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          type="text"
          value={value}
          isRequired={isRequired}
          error={getIn(touched, name) && !!getIn(errors, name)}
          {...props}
        />
      ) : (
        <Input
          autoFocus={autoFocus}
          data-cy={name}
          id={name}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          type="text"
          value={value}
          isRequired={isRequired}
          error={getIn(touched, name) && !!getIn(errors, name)}
          {...props}
        />
      )
    }
    {...props}
  />
)

TextField.propTypes = {
  autoFocus: PropTypes.bool,
  errors: PropTypes.object,
  fieldType: PropTypes.oneOf(["input", "textarea"]),
  label: PropTypes.string,
  labelWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelPosition: PropTypes.oneOf(["top", "middle"]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.string,
  touched: PropTypes.object,
  validate: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  counterMaxVal: PropTypes.bool,
}

TextField.defaultProps = {
  fieldType: "input",
  errors: {},
  touched: {},
  validate: () => "",
  counterMaxVal: false,
  labelPosition: "middle",
}

export default TextField
