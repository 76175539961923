/** Created by Tomáš Korec on 2019/10/22 */

import React, { useContext } from "react"
import Router from "next/router"
import { isBrowser } from "../authManager"
import { CookieContext } from "../cookiesManager"
import { hasRole } from "../rolesManager"
import { useB2BExported } from "../../utils/hooks"

export const withAuthorization = (Component, accessRoles, readonlyRoles = []) => props => {
  const { user } = useContext(CookieContext)
  const isExported = useB2BExported()
  const userRoles = !isExported && user && user.roles ? user.roles : {}
  const writePermission = !isExported && hasRole(userRoles, accessRoles)

  if (
    !writePermission &&
    !hasRole(userRoles, readonlyRoles) &&
    window.localStorage.getItem("B2BExported") !== "true"
  ) {
    isBrowser() && Router.back()
    return null
  }

  return <Component readOnly={!writePermission} {...props} />
}
