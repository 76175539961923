const env = {
  LOCAL: "localhost",
  BETA: "beta",
  DEVEL: "devel",
  TEST: "test",
  PROD: "prod",
}

const envUrl = {
  beta: "//b2b-beta.ipex.cz",
  devel: "//b2b-devel.ipex.cz",
  test: "//b2b-test.voipex.io",
  prod: "//b2b.ipex.cz",
}

const restApiV2Url = {
  beta: "https://restapi-beta.ipex.cz/v2/",
  devel: "https://restapi-devel.ipex.cz/v2/",
  test: "https://restapi-test.voipex.io/v2/",
  prod: "https://restapi.ipex.cz/v2/",
}

const pbxApiUrl = {
  beta: "https://ipbxapi-beta.voipex.io/",
  devel: "https://ipbxapi-dev.voipex.io/",
  test: "https://ipbxapi-test.voipex.io/",
  prod: "https://ipbxapi.voipex.io/",
}

const flowApiUrl = {
  beta: "https://flow-beta.voipex.io/",
  devel: "https://flow-devel.voipex.io/",
  test: "https://flow-test.voipex.io/",
  prod: "https://flow.voipex.io/",
}

const requestorUrl = {
  beta: "https://ipex.servicedesk.net/",
  devel: "https://ipex-test.servicedesk.net/",
  test: "https://ipex-test.servicedesk.net/",
  prod: "https://ipex.servicedesk.net/",
}

export const getEnv = apiUrl => {
  if (apiUrl.includes(env.BETA)) return env.BETA
  if (apiUrl.includes(env.DEVEL) || apiUrl.includes(env.LOCAL)) return env.DEVEL
  if (apiUrl.includes(env.TEST)) return env.TEST
  return env.PROD
}

export const getEnvUrl = env => envUrl[env]

export const getPbxApiUrl = env => pbxApiUrl[env]

export const getRestApiV2Url = env => restApiV2Url[env]

export const getFlowApiUrl = env => flowApiUrl[env]

export const getRequestorUrl = env => requestorUrl[env]

export function checkIframe() {
  if (typeof window === "undefined") return false
  try {
    // Browsers can block access to window.top due to same origin policy
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export function getWindowWidth() {
  if (typeof window === "undefined") return false
  return window.innerWidth
}
