/** Created by Tomáš Korec on 2019/08/17 */

import React, { useContext, useReducer } from "react"
import PropTypes from "prop-types"

import cz from "../static/cz.json"
import en from "../static/en.json"

import { storeLanguage, LANG_KEY } from "../core"

export const languages = {
  EN: "en",
  CZ: "cz",
}

const defaultState = {
  lang: languages.CZ,
  messages: cz,
}

export const getLanguageFromQuery = query => query && query[LANG_KEY]

export const LanguageContext = React.createContext(defaultState)

export const LanguageProvider = ({ children, initialLanguage }) => {
  const reducer = (state, action) => {
    if (action.type === "setLanguage") {
      return {
        lang: action.payload,
        messages: action.payload === languages.CZ ? cz : en,
      }
    } else {
      throw Error("Unsupported action")
    }
  }

  const initialState = initialLanguage
    ? {
        lang: initialLanguage,
        messages: initialLanguage === languages.CZ ? cz : en,
      }
    : defaultState

  const [state, dispatch] = useReducer(reducer, initialState)

  const setLanguage = language => {
    storeLanguage(language)
    dispatch({ type: "setLanguage", payload: language })
  }

  return (
    <LanguageContext.Provider value={{ ...state, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useI18n = () => {
  const context = useContext(LanguageContext)

  if (!context) {
    throw new Error("useI18n must be used within LanguageContext provider")
  }

  return context
}

LanguageProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  initialLanguage: PropTypes.string,
}

// class based components wrapper
export const withIntl = WrappedComponent => props => {
  const { messages } = useI18n()
  return <WrappedComponent messages={messages} {...props} />
}
